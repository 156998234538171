import PartySocket from "partysocket";

declare global {
  interface Window {
    party: {
      send: (message: string) => void;
      addEventListener: (event: string, callback: (event: CustomEvent) => void) => void;
    };
    sendPaintAction: (x: number, y: number, mode: string) => void;
    sendChatMessage: (username: string, message: string) => void;
    handleIncomingMessage: (callback: (data: any) => void) => void;
    partyInitialized: Promise<void>;
  }
}

let partySocket: PartySocket;

function initializeParty() {
  return new Promise<void>((resolve, reject) => {
    partySocket = new PartySocket({
      host: window.location.hostname === 'localhost' ? 
        'http://127.0.0.1:1999' : 
        'https://bsouth.b1ind.partykit.dev',
      room: "my-new-room",
    });

    partySocket.addEventListener("message", (event) => {
      try {
        const data = JSON.parse(event.data);
        if (data.type === 'paint' || data.type === 'chat') {
          window.dispatchEvent(new CustomEvent('partyMessage', { detail: data }));
        }
      } catch (error) {
        console.error("Error parsing message:", error);
      }
    });

    partySocket.addEventListener("open", () => {
      console.log("Connected to PartyKit server");
      setupGlobalFunctions();
      resolve();
    });

    partySocket.addEventListener("error", (error) => {
      console.error("WebSocket error:", error);
      reject(error);
    });

    partySocket.addEventListener("close", (event) => {
      console.log("WebSocket closed:", event);
      // Attempt to reconnect after a delay
      setTimeout(() => initializeParty(), 5000);
    });
  });
}

function setupGlobalFunctions() {
  window.party = {
    send: (message: string) => {
      if (partySocket && partySocket.readyState === WebSocket.OPEN) {
        partySocket.send(message);
      } else {
        console.error("WebSocket is not open. Message not sent.");
      }
    },
    addEventListener: (event: string, callback: (event: CustomEvent<any>) => void) => {
      window.addEventListener('partyMessage', callback as EventListener);
    }
  };

  window.sendPaintAction = function(x: number, y: number, mode: string) {
    window.party.send(JSON.stringify({ type: 'paint', x, y, mode }));
  };

  window.sendChatMessage = function(username: string, message: string) {
    window.party.send(JSON.stringify({ type: 'chat', username, message }));
  };

  window.handleIncomingMessage = function(callback: (data: any) => void) {
    window.party.addEventListener('partyMessage', (event: CustomEvent) => {
      callback(event.detail);
    });
  };

  console.log("Global functions set up");
}

window.partyInitialized = initializeParty()
  .then(() => {
    console.log("PartyKit initialized");
  })
  .catch(error => {
    console.error("Failed to initialize PartyKit:", error);
  });

// Initialize the multiplayer features
document.addEventListener('DOMContentLoaded', () => {
  window.partyInitialized.then(() => {
    console.log("Multiplayer features initialized");
    
    // Handle incoming messages
    window.handleIncomingMessage((data) => {
      if (data.type === 'paint') {
        (window as any).toggleGridSquare(data.x, data.y, data.mode);
      } else if (data.type === 'chat') {
        const chatContainer = document.querySelector('.chat-messages');
        if (chatContainer) {
          (window as any).addMessage(data.username, data.message, chatContainer);
        } else {
          console.error("Chat container not found");
        }
      }
    });
  });
});

// Expose global functions to window object
(window as any).sendPaintAction = window.sendPaintAction;
(window as any).sendChatMessage = window.sendChatMessage;